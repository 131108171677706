@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: local('Montserrat-Italic'), url(./fonts/Montserrat-Italic.ttf) format('truetype');
}

html {
  height: 100%;
}

#root{
  min-width: 100%;
  display: flex;
  height:100%;
}

body {
  /* overflow: hidden; */
  /* padding-right: 3%; */
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* height:100%;
  min-height: 100%; */
  margin: 0;
  font-family: 'Montserrat-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list-results-info{
  font-weight: bold;
  font-size: 12px;
}
.programs-list-table{
  overflow-x: auto !important;
}

.message-row td{
  border-bottom: none !important;
}

.iframe-holder{
  background:url(/loader.gif) center center no-repeat;
  background-size: 80px 80px;
}

.App {
  width:100%;
}

.v-separator{
  border-left: 1px solid
}

.copy-link {
  margin-left: 10px;
  padding-left: 5px;
}

.filters-menu > div.MuiMenu-paper{
  overflow-x: hidden !important;
  max-width: 350px !important;
}

.filters-menu__options {
  white-space: break-spaces !important;
}

.registration-action {
  margin-bottom: 15px;
}

.registration-details-separator{
  margin-bottom: 25px;
  background-color: lightgrey !important;
  height: 1px;
  border: none;
}

.error-content {
  line-height: 1.5;
}

.error-title {
  font-weight: bold;
  font-size: 18px;
}

/* CSS FOR MARKERS IN MAP */
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

#outlined-size-small {
  padding: 8.5px 7px;
}

/* schedule widget */
.filterOptions > .MuiMenu-paper {
  border-radius: 0 !important;
}